<template>
  <v-card class="rounded-lg" elevation="4">
    <v-card-title>
      <span class="highlight subtitle-1 font-weight-bold onSurface--text">
        회원 정보
      </span>
    </v-card-title>

    <v-card-text>
      <v-avatar size="80" color="surface" class="d-flex mx-auto">
        <v-img :src="userData.photoURL">
          <v-btn
            :href="userData.photoURL"
            target="_blank"
            block
            height="124"
            class="transparent"
          />
        </v-img>
      </v-avatar>
      <div class="text-center mt-2">
        <span> 유저 프로필 </span>
      </div>
    </v-card-text>

    <v-card-text class="px-auto">
      <div v-for="item in items" :key="item">
        <admin-user-profile-edit :data="userData" :dataKey="item" />
      </div>
      <div v-if="$store.getters['auth/user/GET_CLAIMS'].level == 0">
        <v-row class="py-3">
          <v-col cols="4" align-self="center" class="text-center py-1">
            Store
          </v-col>
          <v-col cols="8" class="d-flex py-1">
            <v-btn
              x-small
              class="subtitle-2 font-weight-regular px-0"
              text
              :href="
                'https://console.firebase.google.com/u/0/project/madeall/firestore/data/~2Fusers~2F' +
                userData.uid +
                '?hl=ko'
              "
              target="_blank"
            >
              바로가기
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-3 my-0">
          <v-col cols="4" align-self="center" class="text-center py-1">
            Cloud
          </v-col>
          <v-col cols="8" class="d-flex py-1">
            <v-btn
              x-small
              class="subtitle-2 font-weight-regular px-0"
              text
              :href="
                'https://console.firebase.google.com/u/0/project/madeall/storage/madeall.appspot.com/files/~2FUsers~2F' +
                userData.uid +
                '?hl=ko'
              "
              target="_blank"
            >
              바로가기
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AdminUserProfileEdit from "./AdminUserProfileEdit.vue";

export default {
  name: "Admin-Users-Profile-Default",
  components: { AdminUserProfileEdit },
  props: {
    userData: Object,
  },
  data() {
    return {
      items: [
        "displayName",
        "phoneNumber",
        "email",
        "userClass",
        "level",
        "createdAt",
        "visitedAt",
        "uid",
      ],
    };
  },
  methods: {},
};
</script>

<style></style>
