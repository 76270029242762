<template>
  <v-container fluid class="pa-0 d-flex">
    <div
      :style="
        $vuetify.breakpoint.smAndUp
          ? ''
          : mobileProfileView
          ? 'display: none'
          : 'width: 100vw'
      "
    >
      <v-navigation-drawer
        permanent
        class="surface elevation-1 d-flex"
        :width="$vuetify.breakpoint.smAndUp ? '280px' : '100%'"
        height="100vh"
      >
        <v-card
          height="100vh"
          flat
          style="border: 1px solid var(--v-surface-base)"
        >
          <v-card-title class="title font-weight-bold">
            회원 관리
            <v-spacer />
            <v-btn
              color="green"
              class="white--text"
              small
              @click="excelDownaloadDialog = true"
            >
              다운로드
            </v-btn>
            <v-dialog v-model="excelDownaloadDialog" width="360px">
              <v-card rounded="lg" class="py-3">
                <v-card-title> 개인정보 취급 주의 </v-card-title>
                <v-card-text>
                  <div class="my-3">
                    해당 내용은 다수의 개인정보를 포함하고 있습니다.
                    <br />
                    취급 시 각별한 주의를 필요로 하며, <br />
                    목적 이외의 용도로 사용을 절대 금합니다.
                  </div>
                  <div class="my-3">
                    <v-row class="pt-5">
                      <v-col cols="3" class="text-right py-0"> 열람자 </v-col>
                      <v-col cols="9" class="py-0">
                        {{ $store.getters["auth/user/GET_USER"].displayName }}
                      </v-col>
                      <v-col cols="3" class="text-right py-0"> 내용 </v-col>
                      <v-col cols="9" class="py-0">
                        전체 회원정보 엑셀 다운로드
                      </v-col>
                      <v-col cols="3" class="text-right py-0"> 일시 </v-col>
                      <v-col cols="9" class="py-0">
                        {{ new Date() | moment("YYYY년 MM월 DD일 hh시 mm분") }}
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions class="px-12 pb-7">
                  <v-btn
                    color="surface"
                    class="px-5"
                    @click="exportExcel()"
                    :loading="searchBtnLoading"
                  >
                    다운로드
                  </v-btn>
                  <v-spacer> </v-spacer>
                  <v-btn
                    color="primary"
                    class="px-5"
                    @click="excelDownaloadDialog = false"
                  >
                    닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-col cols="12">
            <v-text-field
              v-model="searchText"
              style="width: 100%"
              class="font-weight-regular"
              flat
              dense
              hide-no-data
              hide-details
              solo
              background-color="surface"
              prepend-inner-icon="mdi-magnify"
              label="UID , Email , 이름 , 연락처"
              @keydown.enter.prevent="
                searchText != searchedText ? userSearch() : ''
              "
            >
              <template #append v-if="searchText != searchedText">
                <v-btn color="primary" type="submit" @click="userSearch">
                  검색
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  block
                  depressed
                  color="transparent"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span> {{ userCategorySelect.name }} </span> <v-spacer />
                  <span class="font-weight-regular">
                    {{ userCategorySelect.count }}
                  </span>
                  <v-icon> mdi-chevron-down </v-icon>
                </v-btn>
              </template>

              <v-list class="">
                <v-btn
                  v-for="item in userCategory"
                  :key="item.name"
                  block
                  depressed
                  color="transparent"
                  class="font-weight-regular"
                  @click="
                    userCategorySelect = item;
                    items = [];
                    userSearch();
                  "
                >
                  {{ item.name }} <v-spacer />
                  {{ item.count }}
                </v-btn>
              </v-list>
            </v-menu>
          </v-col>
          <v-divider />
          <v-col cols="12">
            <v-list
              id="list"
              style="overflow-y: scroll"
              height=" calc(100vh - 220px)"
            >
              <v-list-item v-for="item in items" :key="item.uid" class="pa-0">
                <v-hover v-slot="{ hover }">
                  <v-card
                    flat
                    class="d-flex px-0"
                    :color="hover ? '#dedede' : 'transparent'"
                    width="100%"
                    @click="
                      openDialog(item);
                      mobileProfileView = true;
                    "
                  >
                    <v-badge
                      avatar
                      color="yellow"
                      bordered
                      bottom
                      overlap
                      offset-x="28"
                      offset-y="28"
                      :value="
                        item.uid.split(':')[0] == 'kakao' ||
                        item.uid.split(':')[0] == 'naver'
                      "
                    >
                      <template v-slot:badge>
                        <v-avatar>
                          <v-img
                            :src="
                              item.uid.split(':')[0] == 'kakao'
                                ? require('@/assets/logos/auth/kakao.png')
                                : item.uid.split(':')[0] == 'naver'
                                ? require('@/assets/logos/auth/naver.png')
                                : ''
                            "
                          />
                        </v-avatar>
                      </template>
                      <v-list-item-avatar size="40" class="pa-0">
                        <v-img
                          :src="item.photoURL"
                          style="border: 1px solid #999999"
                        />
                      </v-list-item-avatar>
                    </v-badge>
                    <v-list-item-content class="pl-2 py-0">
                      <v-list-item-title
                        class="subtitle-2"
                        v-text="item.displayName"
                      >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="caption pt-1"
                        v-text="
                          item.phoneNumber.replace(
                            /(\d{3})(\d{4})(\d{4})/,
                            '$1-$2-$3',
                          )
                        "
                      >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="pl-2 pr-4">
                      <v-list-item-action-text>
                        {{
                          item.level == 0
                            ? "개발자"
                            : item.level == 1
                            ? "관리자"
                            : item.level == 2
                            ? "매니저"
                            : item.userClass == 0
                            ? "개인"
                            : item.userClass == 1
                            ? "학생"
                            : item.userClass == 2
                            ? "기업"
                            : ""
                        }}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-card>
                </v-hover>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card>
      </v-navigation-drawer>
    </div>
    <admin-user-profile
      v-if="userData"
      :style="
        $vuetify.breakpoint.smAndUp
          ? ''
          : mobileProfileView
          ? ''
          : 'display:none'
      "
      :userData="userData"
      @mobileViewChange="mobileViewChange"
      @deleteUser="deleteUser"
    />
  </v-container>
</template>

<style scoped>
::v-deep .v-input__slot {
  padding: 0px 0px 0px 6px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgb(241, 241, 241);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgb(192, 192, 192);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
<script>
import moment from "moment";
import ExcelJS from "exceljs";
import flat from "flat";
import FileSaver from "file-saver";
import constants from "@/utils/constants";
import setMeta from "@/utils/setMeta";
import _ from "lodash";
import AdminUserProfile from "../../components/Dialog/Admin/User/AdminUserProfile.vue";

export default {
  name: "Admin-Users",
  components: { AdminUserProfile },

  beforeCreate() {
    setMeta({
      title: "유저 관리",
      description: "메이드올 관리자페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  async mounted() {
    if (this.$route.params.uid) {
      this.searchText = this.$route.params.uid;
    }
    this.totalUser = await this.$axios.get("admin/totalUsersCount");
    this.totalUser = this.totalUser.data;
    this.userCategory = [
      { name: "전체", count: this.totalUser.totalUserCount },
      { name: "개인", count: this.totalUser.totalPersonal },
      { name: "학생", count: this.totalUser.totalStudent },
      { name: "기업", count: this.totalUser.totalBusiness },
      { name: "매니저", count: this.totalUser.totalOperator },
      { name: "관리자", count: this.totalUser.totalAdmin },
    ];
    this.userCategorySelect = this.userCategory[0];
    await this.userSearch();

    setTimeout(() => {
      document.getElementById("list").onscroll = () => {
        let bottomOfWindow =
          document.getElementById("list").scrollTop +
            document.getElementById("list").offsetHeight ===
          document.getElementById("list").scrollHeight;
        if (bottomOfWindow && !this.searchText) {
          this.userSearch();
        }
      };
    }, 500);
  },
  watch: {
    options: {
      handler() {
        this.userSearch();
      },
      deep: true,
    },
  },

  data() {
    return {
      // 카테고리 기능
      totalUser: {},
      userCategory: [],
      userCategorySelect: {},
      // 검색 조건 변수
      userClaimSearch: "all",
      userClassSearch: "all",
      searchText: "",
      searchedText: "",
      searchBtnLoading: false,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },

      // Pagination
      itemsPerPage: 25,
      totalCount: 0,
      items: [],

      // Excel Download
      excelDownaloadDialog: false,

      // User Dialog
      userData: "",
      mobileProfileView: false,
    };
  },

  methods: {
    // 조회버튼 검색
    userSearch() {
      var params = {
        offset: this.items.length,
        limit: this.itemsPerPage,
        order: this.options.sortBy[0],
        sort: this.options.sortDesc[0] ? "desc" : "asc",
        searchText: this.searchText,
        searchClaim: "all",
        searchClass: "all",
      };
      if (this.userCategorySelect.name == "매니저") params.searchClaim = 2;
      if (this.userCategorySelect.name == "관리자") params.searchClaim = 1;
      if (this.userCategorySelect.name == "개인") params.searchClass = 0;
      if (this.userCategorySelect.name == "학생") params.searchClass = 1;
      if (this.userCategorySelect.name == "기업") params.searchClass = 2;
      this.$axios
        .get("admin/users", {
          params: params,
        })
        .then(({ data }) => {
          this.totalCount = data.totalCount;
          if (this.searchText || (this.searchedText && !this.searchText))
            this.items = [];
          data.items.forEach((v) => {
            this.items.push(v);
          });
          if (this.userData == "") {
            this.openDialog(this.items[0]);
          }
          this.searchedText = this.searchText;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        });
    },
    openDialog(value) {
      this.userData = _.merge(_.cloneDeep(constants.userInterface), value);
    },
    deleteUser(uid) {
      this.items.splice(
        this.items.findIndex((v) => v.uid == uid),
        1,
      );
      this.userData = {};
      this.userData = this.items[0];
    },
    mobileViewChange() {
      this.mobileProfileView = false;
    },
    // 엑셀 데이터 내보내기
    async exportExcel() {
      this.searchBtnLoading = true;
      this.$toasted.global.error(
        "파일 생성에 시간이 소요될 수 있습니다. 잠시만 기다려주세요.",
      );
      const items = await this.$axios.get("admin/allUsers");

      // create Workbook
      const workbook = new ExcelJS.Workbook();

      // workbook option
      workbook.creator = "MadeAll Inc.";
      workbook.lastModifiedBy =
        this.$store.getters["auth/user/GET_USER"].displayName;

      // add sheet
      const sheet = workbook.addWorksheet("My Sheet");
      sheet.getRow(1).values = ["(주) 메이드올", "홈페이지 이용자 목록 조회"];
      sheet.getRow(2).values = [
        "열람자",
        this.$store.getters["auth/user/GET_USER"].displayName,
        "개인정보 보호를 위하여 사용 후 본 파일은 꼭 복구 불가능한 방법으로 폐기하여야 합니다.",
      ];
      sheet.getRow(3).values = [
        "저장일시",
        moment(new Date()).format("YYYY년MM월DD일 hh시mm분ss초"),
        "개인정보 유출로 인한 사고가 발생하지 않도록 유의합시다.",
      ];
      sheet.mergeCells("C2: H2");
      sheet.mergeCells("C3: H3");
      sheet.getRow(4).values = [
        "이름",
        "이메일",
        "연락처",
        "구분",
        "권한",
        "가입일",
        "학교",
        "학번",
        "전공계열",
        "기업명",
        "업태",
        "업종",
        "UID",
      ];
      sheet.columns = [
        { key: "displayName", width: 17 },
        { key: "email", width: 30 },
        { key: "phoneNumber", width: 15, numFmt: "@" },
        { key: "userClass", width: 6 },
        { key: "level", width: 7 },
        { key: "createdAt", width: 17 },
        { key: "schoolInfo.school", width: 22 },
        { key: "schoolInfo.studentId", width: 12, numFmt: "@" },
        { key: "schoolInfo.subject", width: 20 },
        { key: "companyInfo.companyName", width: 20 },
        { key: "companyInfo.class", width: 20 },
        { key: "companyInfo.productClass", width: 20 },
        { key: "uid", width: 55 },
      ];
      sheet.addRows(items.data.map((row) => flat(row)));
      sheet.autoFilter = {
        from: "A4",
        to: { row: sheet.lastRow.number, column: sheet.lastColumn.number },
      };

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/xlsx" });
      FileSaver.saveAs(blob, "회원목록.xlsx");

      this.searchBtnLoading = false;
    },
  },
};
</script>
