<template>
  <v-card class="rounded-lg" elevation="4">
    <v-card-title>
      <span class="highlight subtitle-1 font-weight-bold onSurface--text">
        주문 내역
      </span>
    </v-card-title>
    <v-card-text>
      <v-progress-circular
        class="d-flex mx-auto mt-5"
        v-if="getOrderLoading"
        color="primary"
        indeterminate
        size="40"
      />
      <div v-if="myOrders.length == 0" class="mt-3 mb-6 pl-3">
        주문 기록이 없습니다.
      </div>
      <div v-else>
        <div v-for="myOrders in myOrders" :key="myOrders.id" class="mt-3 mb-6">
          <v-card
            outlined
            elevation="3"
            rounded="lg"
            width="540px"
            :height="$vuetify.breakpoint.smAndUp ? '115px' : ''"
            class="mb-3 mx-auto"
            @click="openOrderDialog(myOrders)"
          >
            <v-card-text
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'px-7 pt-3 pb-4'
                  : 'px-2 pt-1 pb-2'
              "
            >
              <div class="d-flex">
                <span class="caption">
                  {{ myOrders.workspace ? myOrders.workspace : "작업장 미정" }}
                </span>
                <v-spacer />
                <span class="caption">
                  {{ myOrders.createdAt | moment("YYYY.MM.DD") }}
                </span>
              </div>
              <div
                class="d-flex"
                :class="
                  $vuetify.breakpoint.smAndUp
                    ? 'title font-weight-bold'
                    : 'subtitle-2 font-weight-bold'
                "
              >
                <span>
                  {{ myOrders.purpose }}
                </span>
                <v-spacer />
                <span>
                  {{ myOrders.productCost ? myOrders.productCost : "0" }}
                  원
                </span>
              </div>
            </v-card-text>
            <div class="d-flex caption text-center grey--text text--lighten-1">
              <div class="progress" :style="progressBar(myOrders.status, 0)">
                {{ $vuetify.breakpoint.smAndUp ? "견적접수" : "" }}
              </div>
              <div class="progress" :style="progressBar(myOrders.status, 1)">
                {{ $vuetify.breakpoint.smAndUp ? "상담중" : "" }}
              </div>
              <div class="progress" :style="progressBar(myOrders.status, 3)">
                {{ $vuetify.breakpoint.smAndUp ? "결제완료" : "" }}
              </div>
              <div class="progress" :style="progressBar(myOrders.status, 4)">
                {{ $vuetify.breakpoint.smAndUp ? "작업대기" : "" }}
              </div>
              <div class="progress" :style="progressBar(myOrders.status, 5)">
                {{ $vuetify.breakpoint.smAndUp ? "작업중" : "" }}
              </div>
              <div class="progress" :style="progressBar(myOrders.status, 6)">
                {{ $vuetify.breakpoint.smAndUp ? "발송" : "" }}
              </div>
            </div>
          </v-card>
        </div>
        <v-pagination
          class="mx-auto pt-0 pb-5"
          v-model="myOrdersPage"
          :length="1"
          total-visible="7"
          @input="getOrderData"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-slider {
  background-color: red;
}
.progress {
  width: 90px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>
<script>
export default {
  name: "Admin-Users-Dialog",
  components: {},
  props: {
    userData: Object,
  },
  data() {
    return {
      getOrderLoading: false,
      myOrders: [],

      myOrdersItemPerPage: 3,
      myOrdersPage: 1,
      myOrdersPageCount: 0,
    };
  },
  mounted() {
    this.myOrders = [];
    this.getOrderData();
  },
  watch: {
    userData() {
      this.myOrders = [];
      this.getOrderData();
    },
  },
  methods: {
    async getOrderData() {
      this.getOrderLoading = true;
      await this.$axios
        .get("orders/", {
          params: {
            uid: this.userData.uid,
            offset: this.myOrders.length,
            limit: this.myOrdersItemPerPage,
          },
        })
        .then((v) => {
          const data = v.data;
          data.forEach((d) => {
            this.myOrders.push(d);
          });
          this.getOrderLoading = false;
        });
    },
    progressBar(index, step) {
      var res = "";
      if (index >= step) {
        switch (index) {
          case 0:
            res = "border-top: 5px #FED466 solid;";
            break;
          case 1:
            res = "border-top: 5px #FEC23E solid;";
            break;
          case 2:
            res = "border-top: 5px #FEC23E solid;";
            break;
          case 3:
            res = "border-top: 5px #FEA500 solid;";
            break;
          case 4:
            res = "border-top: 5px #40B6FF solid;";
            break;
          case 5:
            res = "border-top: 5px #0090FF solid;";
            break;
          case 6:
            res = "border-top: 5px #0B86E3 solid;";
            break;
        }
      } else {
        res = "border-top: 5px #E1E2E6 solid;";
      }
      var text = "";
      if (index == step || (index == 2 && step == 1))
        switch (index) {
          case 0:
            text = "color: #FED466";
            break;
          case 1:
            text = "color: #FEC23E";
            break;
          case 2:
            text = "color: #FEC23E";
            break;
          case 3:
            text = "color: #FEA500";
            break;
          case 4:
            text = "color: #40B6FF";
            break;
          case 5:
            text = "color: #0090FF";
            break;
          case 6:
            text = "color: #0B86E3";
            break;
        }

      return res + text;
    },
  },
};
</script>

<style></style>
