<template>
  <v-row class="py-3">
    <v-col cols="4" align-self="center" class="text-center py-1">
      {{ title[dataKey] }}
    </v-col>
    <v-col cols="8" class="d-flex py-1">
      <div v-if="!editValue" style="width: 100%" class="onSurface--text">
        <v-hover v-slot="{ hover }" close-delay="100">
          <div :class="hover ? 'font-weight-medium' : ''" @click="editClick()">
            <span
              v-if="dataKey == 'phoneNumber'"
              v-text="
                data[dataKey]
                  ? data[dataKey].replace(
                      /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
                      '$1-$2-$3',
                    )
                  : '-'
              "
            />
            <span
              v-else-if="dataKey == 'displayName'"
              v-text="data[dataKey] || '-'"
            />
            <span
              v-else-if="dataKey0 == 'schoolInfo' || dataKey0 == 'companyInfo'"
              v-text="
                dataKey == 'companyId'
                  ? data[dataKey0][dataKey]
                    ? data[dataKey0][dataKey].replace(
                        /^(\d{0,3})(\d{0,3})(\d{0,5})$/g,
                        '$1-$2-$3',
                      )
                    : '-'
                  : data[dataKey0][dataKey]
                  ? data[dataKey0][dataKey]
                  : '-'
              "
            />
          </div>
        </v-hover>
        <div v-if="dataKey == 'email' && !dataKey0">
          <span v-text="data[dataKey]" />
          <v-btn small icon>
            <feather
              v-if="data.emailVerified"
              size="18"
              type="check-circle"
              stroke="green"
            />
            <feather v-else size="18" type="alert-circle" stroke="red" />
          </v-btn>
        </div>
        <v-menu
          v-else-if="dataKey == 'level'"
          rounded="lg"
          offset-x
          nudge-right="8"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="px-0" x-small v-bind="attrs" v-on="on">
              <feather
                size="18"
                :type="levelItem[data[dataKey]].icon"
                :stroke="
                  levelItem[data[dataKey]].color || 'var(--v-onSurface-base)'
                "
              />
              <span
                class="font-weight-regular ml-2 subtitle-2"
                v-text="levelItem[data[dataKey]].text"
              />
            </v-btn>
          </template>

          <v-list
            v-if="$store.getters['auth/user/GET_CLAIMS'].level <= 1"
            class="pa-0"
            dense
          >
            <div v-for="(item, index) in levelItem" :key="index">
              <v-list-item
                v-if="index != levelItem.length - 1"
                @click="
                  saveData = index;
                  save();
                "
              >
                <v-list-item-title class="body-2">
                  <feather
                    size="18"
                    :type="item.icon"
                    :stroke="item.color"
                    class="mr-2"
                  />
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
        <v-menu
          v-else-if="dataKey == 'userClass'"
          rounded="lg"
          offset-x
          nudge-right="8"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text x-small class="px-0" v-bind="attrs" v-on="on">
              <span
                class="font-weight-regular subtitle-2"
                v-text="className[data[dataKey]]"
              />
            </v-btn>
          </template>

          <v-list class="pa-0" dense>
            <v-list-item
              v-for="(item, index) in className"
              :key="index"
              @click="
                saveData = index;
                save();
              "
            >
              <v-list-item-title class="subtitle-2">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <span v-else-if="dataKey == 'createdAt'">
          {{ data[dataKey] | moment("YYYY년 MM월 DD일 hh시 mm분") }}
        </span>
        <span v-else-if="dataKey == 'visitedAt'">
          {{ data[dataKey] | moment("YYYY년 MM월 DD일 hh시 mm분") }}
        </span>
        <span
          v-else-if="dataKey == 'uid'"
          style="display: inline-block !important; width: 100%"
        >
          {{ data[dataKey] }}
          <v-btn
            v-clipboard:copy="data.uid"
            v-clipboard:success="
              () => {
                $toasted.global.success('클립보드에 저장했습니다.');
              }
            "
            v-clipboard:error="
              () => {
                $toasted.global.error('복사를 실패했습니다.');
              }
            "
            small
            color="grey lighten-1"
            icon
          >
            <feather size="18" type="copy" />
          </v-btn>
        </span>
      </div>
      <div v-else style="width: 100%">
        <v-autocomplete
          v-if="dataKey == 'school'"
          v-click-outside="onClickOutside"
          v-model="saveData"
          :items="schoolList"
          cache-items
          single-line
          hide-no-data
          hide-details
          clearable
          label="학교를 선택해주세요"
        />
        <v-text-field
          v-else
          v-click-outside="onClickOutside"
          dense
          outlined
          hide-details
          class="subtitle-2"
          label="Enter To Save"
          v-model="saveData"
          @keydown.enter="save()"
          @keydown.esc="onClickOutside()"
        />
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
::v-deep .v-text-field--outlined.v-input--dense .v-label {
  left: 6px !important;
  font-size: 13px;
}
</style>

<script>
import _ from "lodash";
import constants from "@/utils/constants";
export default {
  name: "Admin-Users-Dialog-Edit",
  props: { data: Object, dataKey: String, dataKey0: String },
  data() {
    return {
      editValue: false,
      title: {
        displayName: "성함",
        phoneNumber: "연락처",
        email: "이메일",
        level: "권한",
        userClass: "구분",
        createdAt: "가입일",
        visitedAt: "접속일",
        uid: "UID",
        school: "학교",
        studentId: "학번",
        companyName: "상호",
        companyId: "사업자번호",
        cheifName: "대표자명",
        class: "업종",
        productClass: "업태",
      },
      className: ["개인회원", "학생회원", "기업회원"],
      levelItem: "",
      saveData: "",
      schoolList: constants.schoolList,
      saveLoading: false,
    };
  },
  created() {
    this.levelItem = _.cloneDeep(constants.levels);
  },
  methods: {
    editClick() {
      if (this.dataKey0) this.saveData = this.data[this.dataKey0][this.dataKey];
      else {
        this.saveData = this.data[this.dataKey];
      }
      this.editValue = true;
    },
    onClickOutside() {
      this.editValue = false;
      this.saveData = this.data[this.dataKey];
    },
    async save() {
      this.editValue = false;
      if (this.saveLoading) {
        this.$toasted.global.error("이미 저장중인 내용이 있습니다.");
        return;
      }
      this.saveLoading = true;
      if (this.dataKey0) this.data[this.dataKey0][this.dataKey] = this.saveData;
      else {
        this.data[this.dataKey] = this.saveData;
      }
      try {
        await this.$axios.patch("admin/userUpdate/" + this.data.uid, this.data);
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
      this.saveLoading = false;
    },
  },
};
</script>
