<template>
  <v-card class="px-5 mx-auto" width="100%" max-width="1580px" flat>
    <!-- 상단 삭제버튼  -->
    <v-card-title>
      <v-btn
        @click="$emit('mobileViewChange')"
        class="black--text"
        icon
        v-if="!$vuetify.breakpoint.smAndUp"
      >
        <feather type="sidebar" />
      </v-btn>
      <v-spacer />
      <v-btn
        color="transparent"
        class="rounded-lg font-weight-bold error--text"
        depressed
        @click="dialogDelete = true"
      >
        <feather size="18" type="trash" class="mr-1" />
        회원 삭제
      </v-btn>
      <!-- 삭제 확인 창 -->
      <v-dialog v-model="dialogDelete" max-width="320px">
        <v-card class="rounded-xl text-center pa-2" flat>
          <v-card-title class="title font-weight-bold">
            선택한 회원을 삭제하겠습니까?
          </v-card-title>
          <v-card-subtitle class="ma-0">
            해당 회원이 즉시 삭제됩니다. <br />이 동작은 실행 취소할 수
            없습니다.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary rounded-lg"
              depressed
              @click="dialogDelete = false"
            >
              취소
            </v-btn>
            <v-btn
              color="surface rounded-lg"
              :loading="deleteBtnLoading"
              depressed
              @click="deleteUser"
            >
              삭제
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-row>
      <v-col cols="12" md="4">
        <admin-user-profile-default :userData="userData" />
      </v-col>
      <v-col cols="12" md="4">
        <admin-user-profile-additional :userData="userData" />
      </v-col>
      <v-col cols="12" md="4">
        <admin-user-profile-orders :userData="userData" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import AdminUserProfileOrders from "./AdminUserProfileOrders.vue";
import AdminUserProfileAdditional from "./AdminUserProfileAdditional.vue";
import AdminUserProfileDefault from "./AdminUserProfileDefault.vue";

export default {
  name: "Admin-Users-Dialog",
  components: {
    AdminUserProfileOrders,
    AdminUserProfileAdditional,
    AdminUserProfileDefault,
  },
  props: {
    userData: Object,
    mobileProfileView: Boolean,
  },
  data() {
    return {
      // 유저 삭제
      dialogDelete: false,
      deleteBtnLoading: false,
    };
  },
  methods: {
    // 회원 삭제
    async deleteUser() {
      this.deleteBtnLoading = true;
      try {
        await this.$axios.delete("users/" + this.userData.uid, {
          data: {
            level: this.userData.level,
            class: this.userData.userClass,
          },
        });
      } catch (e) {
        console.log(e);
      }
      this.dialogDelete = false;
      this.deleteBtnLoading = false;
      this.$emit("deleteUser", this.userData.uid);
    },
  },
};
</script>

<style></style>
