<template>
  <v-card class="rounded-lg" elevation="4">
    <v-card-title>
      <span class="highlight subtitle-1 font-weight-bold onSurface--text">
        추가 정보
      </span>
    </v-card-title>
    <!-- 본문 -->
    <v-card-text class="px-auto pt-3">
      <v-row class="py-3">
        <v-col cols="4" align-self="center" class="text-center py-1">
          이용약관
        </v-col>
        <v-col
          cols="8"
          class="d-flex py-1"
          :class="userData.agree.rule ? 'primary--text' : 'error--text'"
        >
          {{ userData.agree.rule ? "동의함" : "미동의" }}
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="4" align-self="center" class="text-center py-1">
          개인정보이용
        </v-col>
        <v-col
          cols="8"
          class="d-flex py-1"
          :class="userData.agree.userInfo ? 'primary--text' : 'error--text'"
        >
          {{ userData.agree.userInfo ? "동의함" : "미동의" }}
        </v-col>
      </v-row>
      <v-row class="py-3">
        <v-col cols="4" align-self="center" class="text-center py-1">
          마케팅
        </v-col>
        <v-col
          cols="8"
          class="d-flex py-1"
          :class="userData.agree.marketing ? 'primary--text' : 'error--text'"
        >
          {{ userData.agree.marketing ? "동의함" : "미동의" }}
        </v-col>
      </v-row>
      <!-- 학생회원 추가정보 -->
      <div class="d-flex mt-6 mb-3 ml-3">
        <span class="subtitle-2 font-weight-bold onSurface--text">
          학생 정보
        </span>
      </div>
      <div v-for="item in schoolItems" :key="item">
        <admin-user-profile-edit
          :data="userData"
          :dataKey="item"
          :dataKey0="'schoolInfo'"
        />
      </div>
      <!-- 기업회원 추가정보 -->
      <div class="d-flex mt-6 mb-3 ml-3">
        <span class="subtitle-2 font-weight-bold onSurface--text">
          기업 정보
        </span>
      </div>
      <div v-for="item in companyItems" :key="item">
        <admin-user-profile-edit
          :data="userData"
          :dataKey="item"
          :dataKey0="'companyInfo'"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AdminUserProfileEdit from "./AdminUserProfileEdit.vue";
export default {
  name: "Admin-Users-Profile-Additional",
  components: { AdminUserProfileEdit },
  props: {
    userData: Object,
  },
  data() {
    return {
      schoolItems: ["school", "studentId"],
      companyItems: [
        "companyName",
        "companyId",
        "cheifName",
        "class",
        "productClass",
        "email",
      ],
    };
  },
  methods: {},
};
</script>
